<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          @updateData="updateData"
          :triggerSave.sync="triggerSave"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import formEdit from './add'
import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Create",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      time_zone_local : moment.tz.guess(true),
      triggerSave: false
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editVisit' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Detail' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_VISIT);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    async updateData() {
      await this.getData(this.models.id);
    },
    backToList() {
      this.$router.push({ path: '/admin/list-visit' });
    },
    UpdateActive(key){
      // console.log("key",key)
      var uid = this.$route.params.id;
      this.$commonFuction.is_loading(true);
      if(key == 'lock'){
        this.active_check = false
      }else{
        this.active_check = true
      }

      let variables = {
        'data_update': {
          'active': this.active_check
        },
        'where_key': {
          'id': {'_eq': this.$route.params.id}
        }
      };
      var that = this;
      this.$apollo.mutate({
        mutation: DataServices.updateData('users'),
        variables: variables
      }).then(async (res) => {
        console.log('Success',res);
        if(key == 'lock'){
          var query_t =  `mutation {
            delete_jwt_token(where: { uid: {_eq: "${uid}"}}) {
              returning {
                id
              }
            }
          }`
          await that.$apollo.mutate({
            mutation: gql(query_t),
          })
        }
        that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
      })
      this.$commonFuction.is_loading(false);
      // console.log("this.$route.params.id",this.$route.params.id)
    },
    async saveData(params) {
      // console.log("params",params)
      let objects = {
        arrival: params.arrival,
        departure: params.departure,
        updated_at: moment.tz(new Date(), this.time_zone_local),
        updated_by: this.$store.getters.user
      }
      var people = params.people;
      this.triggerSave = false;
      if(this.mode == 'add'){
        objects.created_at = moment.tz(new Date(), this.time_zone_local);
        objects.created_by = this.$store.getters.user;
        await this.$apollo.mutate({
            mutation: DataServices.insertData('visit'),
            variables: {
              objects: objects
            }
        }).then(async (res) => {
            // console.log("res",res);
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              console.log(returning);
              this.redirectEdit();
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });
      }else{
        // var user_id = this.$route.params.id;
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        }
        await this.$apollo.mutate({
            mutation: DataServices.updateData('visit'),
            variables: variables
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              var query_w = DataServices.deleteData('visit_people')
              var variables_w = {
                where_key: {
                  visit_id: {'_eq': returning.id}
                },
              }

              this.$apollo.mutate({
                mutation: query_w,
                variables: variables_w
              })
              console.log("people", people);
              if (people.length > 0) {
                for (let i=0;i < people.length; i++) {
                  let obj = {
                    name: people[i].fullname,
                    personal_id: people[i].personal_id,
                    is_child: people[i].is_child,
                    visit_id: returning.id,
                    type: 1,
                    updated_by: people[i].updated_by,
                    updated_at: people[i].updated_at
                  };
                  await this.$apollo.mutate({
                      mutation: DataServices.insertData('visit_people'),
                      variables: {
                        objects: obj
                      }
                  }).then(async (resp) => {
                    var returned = resp.data[Object.keys(resp.data)].returning[0];
                    var list_file_upload = [];
                    if (people[i].images && people[i].images.length>0){
                      return Promise.all(
                      // for (let j = 0; i < people[i].images.length; i++) {
                      people[i].images.map(async m => {
                        var item_file_upload = m;
                        var type_image =item_file_upload?.type ?? null
                        if (type_image == 'old_edit'){
                          let variables_img = {
                            'data_update': {
                              obj_id: returned.id
                            },
                            'where_key': {
                              'id': {'_eq': item_file_upload.id}
                            }
                          }
                          await this.$apollo.mutate({
                              mutation: DataServices.updateData('relationship_file'),
                              variables: variables_img
                          })

                        } else {
                          list_file_upload.push(item_file_upload)
                        }
                      })).then(async () => {
                        if (list_file_upload.length>0) {
                          var list_file_upload_tmp = 0;
                          await this.$CoreService.uploadImages("IMG_PEOPLE", list_file_upload).then(async (resImage) => {
                            if (resImage) {
                              for (let k=0; k<resImage.length; k++) {
                                var objectImage = resImage[k];//lấy 1 tấm hình
                                await this.$CoreService.updateDataImage("IMG_PEOPLE", returned.id, objectImage);
                                list_file_upload_tmp++;
                              }
                            }
                          });
                          
                          if ((i+1) == people.length && list_file_upload_tmp == list_file_upload.length) {
                            this.redirectEdit();
                          }
                        } else {
                          this.redirectEdit();
                        }
                      });
                    }
                    if ((i+1) == people.length) {
                      if (!people[i].images || people[i].images.length == 0) {
                        this.redirectEdit();
                      }
                    }
                  }).catch(async error => {
                      console.log('error', error);
                      this.$toast.add({
                          severity: 'error',
                          summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                          detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                          life: 3000
                      });
                      this.$commonFuction.is_loading(false);
                  });
                }
              } else {
                this.redirectEdit();
              }
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });
      }
      this.$commonFuction.is_loading(false);
    },
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-visit'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        // if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          // router_back.query = { page_transaction: that.page_transaction.toString() };
        // }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('visit', {
          "fields" : `id owner_id uid property {id name } children adults user {id fullname role} owner {id fullname} visit_end visit_start week_number status arrival departure check_out_time check_in_time contact_name contact_phone contact_prefix_phone contact_email orders{id code items{id status qty price created_at service{id name}}} requests{id code items{id payment_status qty price created_at obj_service{id name}}} is_owner histories {id status created_at create_user{id fullname}} peoples{id name is_child personal_id updated_by updated_at update_user{id fullname}}`
        }),
        variables: {
          where_key: {
            id: {'_eq': id},
            deleted: {'_eq': false}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        if (this.models?.peoples) {
          this.models.peoples.map(async m => {
            m.fullname = m.name;
            m.update_name = m.update_user?.fullname;
            var fileData = await this.$apollo.query({
              query: DataServices.getList('relationship_file', {
                "fields" : `id obj_file{url}`
              }),
              variables: {
                where_key: {
                  obj_id: {'_eq': m?.id},
                  type: {'_eq': 'IMG_PEOPLE'}
                }
              },
              fetchPolicy: "network-only"
            })
            var images = fileData?.data?.relationship_file || [];
            let img = [];
            if (images.length > 0) {
              images.map(n => {
                img.push({
                  objectURL: n?.obj_file.url,
                  id: n.id,
                  type: 'old_edit'
                });
              })
            // } else {
            //   m.images =  img;
              m.images = img;
              this.componentKey++;
            }
          })
        }
      }
      this.componentKey++;
    },
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .manager-title-custom{
    color: #1D3118;
    font-size: 18px;
    /* margin-bottom: 16px; */
    padding-bottom: 10px;
    /* border-bottom: 1px solid #EEEEEE; */
    font-weight: 700;
  }
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.p-toolbar-group-right {
  flex-basis: 30%;
}
.p-button-warning.p-button {
  margin: 0 10px;
}
</style>